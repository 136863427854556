<template>
  <div class="order-container">
    <div class="order">
      <div class="left">
        <div class="preview">
          <img
            :src="
              `https://${order.order_images.livepreview.bucket}.s3.amazonaws.com/${order.order_images.livepreview.key}`
            "
          />
        </div>
      </div>
      <div class="right">
        <div class="order-summary">
          <h2>Order</h2>

          <table>
            <tr>
              <td>Order Status</td>
              <td>{{ order.status }}</td>
            </tr>
            <tr>
              <td>Payment Status</td>
              <td>{{ order.payment_status }}</td>
            </tr>
            <tr>
              <td>Order Created</td>
              <td>{{ order.created }}</td>
            </tr>
            <tr v-if="order.tracking_number">
              <td>Tracking #</td>
              <td>{{ order.tracking_number }}</td>
            </tr>
            <tr v-if="order.shipped_date">
              <td>Date Shipped</td>
              <td>{{ order.shipped_date }}</td>
            </tr>
          </table>
        </div>

        <h2>Design</h2>

        <div v-if="order.design" class="design-details">
          <table>
            <tr>
              <td>Wood Type</td>
              <td>{{ order.design.wood_type }}</td>
            </tr>
            <tr>
              <td>Design Text1</td>
              <td>{{ order.design.text1 }}</td>
            </tr>
            <tr>
              <td>Design Icon</td>
              <td><img :src="order.design.icon ? order.design.icon.icon_url : 'xxx'" /></td>
            </tr>
          </table>
        </div>
        <div v-else>
          -- No Design Found --
        </div>

        <h2>Shipping</h2>
        <div v-if="order.shipping_address" class="shipping-details">
          <div class="info-row">
            <div class="value">{{ order.shipping_address.name }}</div>
          </div>
          <div class="info-row">
            <div class="value">{{ order.shipping_address.address.line1 }}</div>
          </div>
          <div class="info-row">
            <div class="value">{{ order.shipping_address.address.line2 }}</div>
          </div>
          <div class="info-row">
            <div class="value">{{ order.shipping_address.address.postal_code }}</div>
          </div>
          <div class="info-row">
            <div class="value">
              {{ order.shipping_address.address.city }}, {{ order.shipping_address.address.state }}
            </div>
          </div>
          <div class="info-row">
            <div class="value">{{ order.shipping_address.address.country }}</div>
          </div>
          <div class="clipboard">
            <img @click="copyAddress" src="@/assets/clipboard.svg" />
          </div>
        </div>
        <div v-if="order.status !== 'shipped' && order.status !== 'delivered'" class="input-group">
          <input v-model="tracking_number" placeholder="tracking #" />
          <button @click="shipit">Mark as Shipped</button>
        </div>
        <div v-if="order.status !== 'delivered'">
          <button @click="setStatus('delivered')" class="small">Mark Delivered</button>
        </div>

        <h2>Actions</h2>

        <div class="button-group">
          <button @click="setStatus('cancelled')" class="small danger">Cancel Order</button>
          <button @click="resendEmail('shipped')" class="small">Resend Shipped Email</button>
          <button @click="resendEmail('delivered')" class="small">Resend Delivered Email</button>
          <!-- <button @click="setStatus('uncaptured')" class="small">Uncaptured</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderUtils from '@/utils/orderUtils';

export default {
  name: 'AdminOrderDesktop',
  data() {
    return {
      order_id: this.$route.params.id,
      tracking_number: '',
    };
  },
  mounted() {
    this.$store.dispatch('admin/getFullOrder', this.$route.params.id);
  },
  methods: {
    copyAddress() {
      let address = `${this.order.shipping_address.name || ''}\n${this.order.shipping_address.address1 || ''}\n${this
        .order.shipping_address.address.line1 || ''}\n${this.order.shipping_address.address.line2 || ''}\n${this.order
        .shipping_address.address.city || ''} ${this.order.shipping_address.address.state || ''}  ${this.order
        .shipping_address.address.postal_code || ''}`;
      navigator.clipboard.writeText(address);
    },
    shipit() {
      orderUtils.updateStatus({
        order_id: this.order_id,
        status: 'shipped',
        tracking_number: this.tracking_number,
        force: true,
      });
    },
    setStatus(newStatus) {
      orderUtils.updateStatus({
        order_id: this.order_id,
        status: newStatus,
        force: true,
      });
    },
    async resendEmail(template) {
      await orderUtils.resendEmail(this.order_id, template);
    },
  },
  computed: {
    order() {
      return this.$store.getters['admin/order'](this.order_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-basis: 100vh;

  .order {
    width: 100%;
    color: white;
    display: grid;
    grid-template-columns: 40% 60%;
    flex-grow: 1;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .preview {
      max-width: 50%;
      border-radius: 10px;
      padding: 10px;

      img {
        width: 100%;
      }
    }
  }

  .info-row {
    display: flex;
    flex-direction: row;

    .title {
      font-weight: 600;
      margin-right: 20px;
    }
  }

  .design-details {
    img {
      max-width: 100px;
      background-color: white;
      padding: 10px;
      border-radius: 10px;
    }
  }

  table {
    text-align: left;

    tr {
      line-height: 30px;
    }

    td:first-of-type {
      font-weight: 600;
      padding-right: 20px;
    }
  }

  .button-group {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;

    button {
      margin-right: 20px;
    }
  }

  .clipboard {
    align-items: flex-start;
    justify-self: flex-start;
    display: flex;

    img {
      width: 30px;
      cursor: pointer;

      &:hover {
        filter: opacity(0.7);
      }
    }
  }
}
</style>
