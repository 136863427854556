<template>
  <div style="height: 100%;">
    <OrderDesktop v-if="$store.state.layout === 'desktop'" />
    <OrderMobile v-if="$store.state.layout === 'mobile'" />
  </div>
</template>

<script>
import OrderDesktop from './OrderDesktop';
import OrderMobile from './OrderMobile';

export default {
  name: 'AdminOrderIndex',
  components: { OrderDesktop, OrderMobile },
};
</script>
