<template>
  <div class="order-container">
    <div class="back-btn" @click="$router.go(-1)">&lt; back</div>

    <div class="first-row">
      <div class="order-summary">
        <table>
          <tr>
            <th>Status</th>
            <td>{{ order.status }}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>{{ order.created | getDate }}</td>
          </tr>
          <tr v-if="order.design">
            <th>Wood</th>
            <td>{{ order.design.wood_type }}</td>
          </tr>
          <tr v-if="order.design">
            <th>Text</th>
            <td>{{ order.design.text1 }}</td>
          </tr>
          <tr v-if="order.design">
            <th>Icon</th>
            <td><img :src="order.design.icon ? order.design.icon.icon_url : 'xxx'" /></td>
          </tr>
        </table>
      </div>

      <div class="preview">
        <img
          :src="
            `https://${order.order_images.livepreview.bucket}.s3.amazonaws.com/${order.order_images.livepreview.key}`
          "
        />
      </div>
    </div>

    <hr />
    <div v-if="order.shipping_address" class="shipping-details">
      <div class="info-row">
        <div class="value">{{ order.shipping_address.name }}</div>
      </div>
      <div class="info-row">
        <div class="value">{{ order.shipping_address.address.line1 }}</div>
      </div>
      <div class="info-row">
        <div class="value">{{ order.shipping_address.address.line2 }}</div>
      </div>
      <div class="info-row">
        <div class="value">{{ order.shipping_address.address.postal_code }}</div>
      </div>
      <div class="info-row">{{ order.shipping_address.address.city }}, {{ order.shipping_address.address.state }}</div>
      <div class="info-row">
        <div class="value">{{ order.shipping_address.address.country }}</div>
      </div>
    </div>

    <div v-if="order.status !== 'shipped' && order.status !== 'delivered'" class="input-group">
      <input placeholder="tracking #" />
      <button>Mark Shipped</button>
    </div>

    <div class="button-group">
      <button v-if="order.status === 'shipped'" @click="resendEmail('shipped')" class="small">
        Resend Shipped Email
      </button>
      <button v-if="order.status === 'delivered'" @click="resendEmail('delivered')" class="small">
        Resend Delivered Email
      </button>
      <button @click="setStatus('cancelled')" class="small danger">Cancel Order</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminOrderMobile',
  data() {
    return {
      order_id: this.$route.params.id,
    };
  },
  mounted() {
    this.$store.dispatch('admin/getFullOrder', this.$route.params.id);
  },
  computed: {
    order() {
      return this.$store.getters['admin/order'](this.order_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100vh;
  width: 100%;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 14px;

  .back-btn {
    align-self: flex-start;
    margin: 10px 0 0 10px;
  }

  .first-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    margin-top: 40px;

    .preview {
      max-width: 25%;
      border-radius: 10px;
      padding: 10px;
      margin-top: -60px;

      img {
        width: 100%;
      }
    }

    .info-row {
      display: flex;
      flex-direction: row;

      .title {
        font-weight: 600;
        margin-right: 20px;
      }
    }
  }

  .button-group {
    width: 100%;

    button {
      white-space: nowrap;
      width: 100%;
      margin: 20px 0;
      white-space: nowrap;
    }
  }

  table {
    text-align: left;

    th {
      padding-right: 10px;
      padding-bottom: 5px;
    }

    img {
      max-width: 25px;
      background-color: white;
      padding: 10px;
      border-radius: 10px;
    }
  }

  .shipping-details {
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    text-align: left;
  }

  button {
    white-space: nowrap;
  }
}
</style>
