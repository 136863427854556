import axios from 'axios';
import store from '@/store';
import config from '@/utils/config';

export default {
  updateStatus: async opts => {
    let { data } = await axios.post(`${config.getValue('VUE_APP_BACKEND_HOST')}/admin/order/status`, opts);
    store.dispatch('admin/updateOrder', data);
  },
  attemptCapture: async opts => {
    await axios.post(`${config.getValue('VUE_APP_BACKEND_HOST')}/admin/order/capture`, opts);
  },
  resendEmail: async (order_id, template) => {
    try {
      await axios.post(`${config.getValue('VUE_APP_BACKEND_HOST')}/admin/order/${order_id}/email/${template}`);
    } catch (ex) {
      alert(ex);
    }
  },
};
